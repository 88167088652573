<template>
  <span>
    <v-row class="py-4 align-center">
      <slot/>
      <v-spacer v-if="store.app.onMobile"/>
      <DropMenu icon="mdi-history" :options="sectionFilters"
                @optionUpdate="$emit('updateSemester', sectionFilters[$event].value)"/>
      <v-spacer v-if="!store.app.onMobile"/>
      <v-row v-if="!store.app.onMobile" justify="end" class="mr-1">
        <v-icon size="28" class="mr-3 arrows" @click="navCarousel('.v-window__prev button')">mdi-chevron-left</v-icon>
        <v-icon color="accent" class="mr-3">{{ historyScreen === 0 ? 'mdi-chart-line' : 'mdi-table' }}</v-icon>
        <h4 class="mr-3 font-weight-medium">{{ historyScreen === 0 ? 'Graph' : 'Table' }}</h4>
        <v-icon size="28" class="arrows" @click="navCarousel('.v-window__next button')">mdi-chevron-right</v-icon>
      </v-row>
    </v-row>
    <v-row>
      <v-carousel v-model="historyScreen" hide-delimiters hide-delimiter-background show-arrows-on-hover height="auto">
        <v-carousel-item>
          <apexchart type="area" :options="chartOptions" :series="historyData.series" height="400px" data-cy="apex_chart" style="width: 100%"/>
        </v-carousel-item>
        <v-carousel-item>
          <v-data-table :headers="tableHeaders" :items="historyTable" disable-sort disable-pagination
                        hide-default-footer mobile-breakpoint="0" style="min-height: 415px">
            <template v-slot:item.drop="{ item }">
              <chip :text="item.drop" class-name="justify-center" style="width: 60px" label drop-rate/>
            </template>
          </v-data-table>
        </v-carousel-item>
      </v-carousel>
    </v-row>
  </span>

</template>

<script>
import { useAllStores } from '@/stores/useAllStores'
import DropMenu from '@/components/DropMenu'
import Chip from '@/components/Chip.vue'

export default {
  name: 'HistoryChart',
  components: { Chip, DropMenu },
  props: {
    type: { type: String, required: true },
    historyData: { type: Object, required: true }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    historyScreen: 0,
    sectionFilters: [
      { label: 'Last 10 Offerings', value: 10 },
      { label: 'Last 15 Offerings', value: 15 },
      { label: 'Last 20 Offerings', value: 20 },
      { label: 'Last 25 Offerings', value: 25 }
    ],
    historyHeadersMobile: [0, 1, 5],
    historyHeaders: [
      { text: 'Offering', value: 'sem' },
      { text: 'Professor(s)', value: 'labels' },
      { text: 'Current', value: 'current' },
      { text: 'Cutoff', value: 'cutoff' },
      { text: 'Capacity', value: 'capacity' },
      { text: 'Drop Rate', value: 'drop' }
    ]
  }),
  mounted () {
    this.$watch('$refs.tagSlides._data.isOverflowing', function (newValue) {
      this.overflowing = newValue
    })
    if (this.type !== 'course') this.historyHeaders[1].text = 'Course'
  },
  computed: {
    historyTable () {
      const data = []
      for (let idx = this.historyData.labels.length; idx--;) {
        data.push({
          sem: !this.store.app.onMobile ? this.historyData.sem[idx].replace('-', ' - ') : this.historyData.sem[idx][0] + '-' + this.historyData.sem[idx].slice(-2),
          labels: this.historyData.labels[idx],
          current: this.historyData.details[idx].cur,
          cutoff: this.historyData.details[idx].cut,
          capacity: this.historyData.details[idx].cap,
          drop: this.historyData.series[0].data[idx]
        })
      }
      return data
    },
    tableHeaders () {
      if (!this.store.app.onMobile) {
        return this.historyHeaders
      } else {
        return this.historyHeaders.filter((element, index) => this.historyHeadersMobile.includes(index))
      }
    },
    chartOptions: function () {
      return {
        chart: {
          toolbar: { show: false, tools: { zoom: false } },
          offsetX: 5,
          redrawOnParentResize: true,
          background: this.store.app.darkMode ? '#0F0F0F' : '#FFFFFF'
        },
        theme: { mode: this.store.app.darkMode ? 'dark' : 'light' },
        dataLabels: {
          enabled: true,
          formatter: (value, opt) => {
            return (value < 0) ? 'N/A' : value
          }
        },
        noData: { text: 'No data available yet', align: 'center', offsetY: -25 },
        grid: { row: { colors: [this.store.app.darkMode ? '#2B2A2A' : '#F3F3F3', 'transparent'], opacity: 0.5 } },
        tooltip: {
          x: {
            formatter: (value, opt) => {
              if (this.historyData.series[0].data[opt.dataPointIndex] >= 0) {
                return 'Drop Rate: ' + this.historyData.series[0].data[opt.dataPointIndex] + '%'
              } else {
                return 'Drop Rate: N/A'
              }
            }
          },
          y: {
            formatter: (value, opt) => {
              return this.historyData.labels[opt.dataPointIndex]
            }
          },
          onDatasetHover: { highlightDataSeries: true }
        },
        colors: [this.store.app.darkMode ? '#1976D2' : '#003C85'],
        xaxis: {
          categories: !this.store.app.onMobile ? this.historyData.sem : this.historyData.sem.map((entry) => entry[0] + '-' + entry.slice(-2)),
          title: { text: 'Semester & Year' },
          tooltip: { enabled: false }
        },
        yaxis: { title: { text: 'Drop Rate %' } }
      }
    }
  },
  methods: {
    navCarousel (direction) {
      document.querySelector(direction).click()
    }
  }
}
</script>

<style scoped>
  >>>.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #333232;
  }
  .dropChip {
    width: 60px;
    font-weight: bold;
    justify-content: center;
  }
  .arrows:focus::after {
    opacity: 0 !important;
  }
  >>>.apexcharts-tooltip {
    color: var(--v-text-base) !important;
  }

</style>
