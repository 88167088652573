<template>
  <div>
    <v-row v-for="(hour, idx) in hours" class="absolute" :key="hour" :style="styleFor(idx)">
      <slot name="interval" v-bind:event="{ hour: hour, day: day, date: day.toString(), time: timeFor(hour) }" />
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['hours', 'day'],
  methods: {
    timeFor (hour) {
      let timeStr = `${hour}:00`
      if (hour < 10) timeStr = '0' + timeStr

      return timeStr
    },
    styleFor (index) {
      const topOffset = (index + 1) * 48 - 35
      return `top: ${topOffset}px;`
    }
  }
}
</script>

<style scoped>
.theme--dark .row {
  border-color: var(--v-greyText-base) !important;
}

.row {
  margin-top: 0;
  height: 48px;
  width: 100%;

  background: var(--v-component-base);
  border: solid 1px var(--v-grey-base);
  border-right: none;
  border-bottom: none;
}
</style>
