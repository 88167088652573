<template>
  <div class="pa-0 calendarContainer overflow-hidden">
    <v-btn v-if="false" @click="debug">debug</v-btn>
    <!-- Headers -->
    <v-row align="end" class="mt-0 pr-3" :class="{ 'mb-2': asyncEvents.length > 0 }">
      <div style="width: 73px;" class="flex-col pa-0" />
      <v-col v-for="(day, dayKey, idx) in eventsForDay" :key="idx" align="center" justify="center" class="dailyHeader">
        <span class="font-weight-bold">
          {{dayKey}}
        </span>
      </v-col>
    </v-row>

    <!-- Async courses -->
    <v-row
        v-for="(event, idx) in asyncEvents"
        :key="idx"
        :style="`margin-top: ${idx === 0 ? -8 : 14}px;`"
    >
      <div style="width: 73px;" class="flex-col pa-0" />
      <v-col :class="`pa-0 asyncEvent ${event.event.color}`" style="height: 24px">
        <slot name="event" v-bind="{ ...event }" />
      </v-col>
    </v-row>

    <v-row class="mt-0 px-3">
      <!-- Time -->
      <div style="width: 61px;" :style="`height: ${height}px;`" class="flex-col pa-0">
        <v-row style="height: 25px"></v-row>

        <v-row v-for="hour in hours" :key="hour" class="hourInterval" style="height: 48px">
          <span class="hourIntervalEntry">
            {{ hour % 12 || 12 }} {{ hour > 11 ? ' PM' : ' AM' }}
          </span>
        </v-row>
      </div>

      <v-col
          v-for="(day, dayKey, idx) in eventsForDay"
          v-if="dayKey !== 'async'"
          :key="idx"

          class="calendarDaily py-0"
          :style="`height: ${height}px;`"
      >
        <!-- Pass slot to child component -->
        <DailyInterval :hours="hours" :day="idx + 1">
          <template v-slot:interval="event">
            <slot name="interval" v-bind="event.event">
            </slot>
          </template>
        </DailyInterval>

        <!-- Event tiles are drawn absolutely -->
        <v-row v-for="(hourBucket, key, idx) in day" :key="idx">
          <v-col v-for="(data, hourBucketIdx) in hourBucket" :key="hourBucketIdx"
                 :class="`pt-0 absolute timedEvent ${data.event.color}`"
                 :style="stylesForEvent(data, hourBucket)"
          >
            <slot name="event" v-bind="{ ...data }">
              N/A
            </slot>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { deepClone } from '@/utils/shared/helpers'
import DailyInterval from '@/components/shared/calendar/DailyInterval.vue'
import Bugsnag from '@bugsnag/js'

export default {
  components: { DailyInterval },
  props: {
    events: {},
    startHour: {
      type: Number,
      default: 8
    },
    endHour: {
      type: Number,
      default: 22
    }
  },
  data () {
    const hours = []
    for (let hour = this.startHour; hour <= this.endHour; hour++) { hours.push(hour) }

    return {
      hours,
      height: (this.endHour - this.startHour + 1) * 48 + 25,
      asyncEvents: [],
      eventsForDay: {}
    }
  },
  mounted () {
    this.getEmptyAssigment()
    this.assignEventsToDays()
  },
  watch: {
    events () {
      this.reset()
      this.assignEventsToDays()
    }
  },
  methods: {
    debug () {
      debugger
    },
    reset () {
      this.eventsForDay = this.getEmptyAssigment()
      this.asyncEvents = []
    },
    /** Event tiles are rendered absolutely */
    stylesForEvent (data, hourBucket) {
      const { event, maxNumOverlaps } = data

      const numHours = event.endHour - event.startHour
      const isMultiHour = numHours > 1

      const index = hourBucket.findIndex((current) => {
        return current.event.id === event.id && current.event.section === event.section
      })

      // Size of each event reduces as number of conflicting events increases.
      // Also, events that are later in the day are offset to the right more.
      let width = (1 / (maxNumOverlaps + 1)) * 100
      let offsetLeft = width * index

      // Mimic the original v-calendar
      // where larger tiles overlap more.
      if (isMultiHour) {
        width += 10 * numHours
      }

      // IMPORTANT! Clamp it to make sure
      // event tiles don't overflow. Kind of hacky...
      if (width > 100) width = 100
      if (offsetLeft > 100) offsetLeft = 100
      if (width + offsetLeft > 100) {
        width -= ((width + offsetLeft) - 100)
      }

      return `
        top: ${(-(this.startHour - 8) + event.startHour - 8) * 48 + 13}px;
        left: ${offsetLeft}%;
        height: ${(event.endHour - event.startHour) * 48}px;
        width: ${width}%;
        overflow: hidden;
      `
    },
    getEmptyAssigment () {
      const newAssignments = {
        MON: {},
        TUE: {},
        WED: {},
        THU: {},
        FRI: {}
        // SAT: {},
        // SUN: {}
      }

      // Only include weekends IF
      // we have an event for it
      if (this.events) {
        const hasSaturdayEvent = this.events.findIndex(event => event.day === 'SAT') > -1
        const hasSundayEvent = this.events.findIndex(event => event.day === 'SUN') > -1

        if (hasSundayEvent) {
          newAssignments.SAT = {}
          newAssignments.SUN = {}
        } else if (hasSaturdayEvent) {
          newAssignments.SAT = {}
        }
      }

      for (const key in newAssignments) {
        this.hours.forEach(hour => { newAssignments[key][hour] = [] })
      }

      return newAssignments
    },
    /** For each event, parse it and add it to the day that it belongs to. */
    assignEventsToDays () {
      console.time('calendar refresh')
      const newAssignments = this.getEmptyAssigment()

      this.events.forEach(event => {
        if (!this.isEventValid(event)) {
          return
        }
        const data = { day: {}, event: event, timed: true }

        const start = new Date(data.event.start)
        const end = new Date(data.event.end)

        data.event.startHour = start.getHours()
        data.event.startMinute = start.getMinutes()

        data.event.endHour = end.getHours()
        data.event.endMinute = end.getMinutes()

        data.day = {
          day: start.getDay()
        }

        // TODO: Not true for async events...
        data.timed = true
        data.singline = end.getHours() - start.getHours() <= 1

        const dayKey = data.event.day

        if (newAssignments[dayKey]) {
          newAssignments[dayKey][start.getHours()].push(data)
        } else {
          this.asyncEvents.push(data)
        }
      })

      // Sort the buckets
      for (const key in newAssignments) {
        const dayBucket = newAssignments[key]

        for (const hourKey in dayBucket) {
          const hourBucket = dayBucket[hourKey]

          hourBucket.forEach(event => this.assignMaximumNumberOfOverlaps(dayBucket, event))

          // Longest events in the front, shorter in the back
          hourBucket.sort((eventA, eventB) => {
            return (
              (eventB.event.endHour - eventB.event.startHour) - (eventA.event.endHour - eventA.event.startHour)
            )
          })
        }
      }

      this.eventsForDay = newAssignments
      console.timeEnd('calendar refresh')
    },
    /**
     * Store the maximum number of events this event
     * overlaps with, across all hour segments.
     * */
    assignMaximumNumberOfOverlaps (dayBucket, event) {
      const { start, end } = event.event
      let maxNumOverlaps = 0
      for (let hour in dayBucket) {
        hour = parseInt(hour)

        const hourBucket = dayBucket[hour]
        const numOverlaps = hourBucket.reduce((prevOverlaps, currentEvent) => {
          // Ignore the event we're trying to measure itself!
          // This only works because we are checking if they equal by
          // reference.
          if (currentEvent === event) return prevOverlaps

          if ((currentEvent.event.start >= start && currentEvent.event.start < end) ||
              (currentEvent.event.end > start && currentEvent.event.end <= end)
          ) {
            // console.log(event.event.id, event.event.section, 'overlaps with', currentEvent.event.id, currentEvent.event.section)
            return prevOverlaps + 1
          }

          return prevOverlaps
        }, 0)

        maxNumOverlaps = Math.max(numOverlaps, maxNumOverlaps)
      }
      event.maxNumOverlaps = maxNumOverlaps
    },
    isEventValid (event) {
      const valid = (
        event && (event.day || !event.timed) &&
        this.dateValid(event.start) &&
        this.dateValid(event.end)
      )
      if (!valid) {
        Bugsnag.notify('Invalid event for timetable', (event) => {
          event.addMetadata('event', deepClone(event))
        })
      }
      return valid
    },
    dateValid (date) {
      if (!date) return false

      if (typeof date === 'string') return true

      return !isNaN(new Date(date).getTime())
    }
  }
}
</script>

<style scoped>
.theme--dark .calendarContainer {
  border-color: var(--v-greyText-base);
}
.calendarContainer {

  border: solid 1px var(--v-grey-base);
  border-radius: 7px;

  background: var(--v-component-base);
}

.calendarDaily {
  position: relative;
}

.theme--dark .dailyHeader {
  border-color: var(--v-greyText-base);
}
.dailyHeader {
  height: 48px;
  border: solid 1px var(--v-grey-base);
  border-top: none;
  border-right: none;
  border-bottom: none;
}

.asyncEvent {
  margin: 0;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
}

.theme--dark .timedEvent {
  border-color: var(--v-tooltip-base) !important;
}
.timedEvent {
  width: 100%;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid white !important;

  padding: 0;
  cursor: pointer;
}

.theme--dark .hourInterval {
  color: var(--v-grey-base);
  border-color: var(--v-greyText-base);
}
.hourInterval {
  position: relative;
  margin-left: 0;

  border-top: solid 1px var(--v-grey-base);
  width: 100%;

  background: var(--v-component-base);

  font-size: 10px;
  color: var(--v-greyText-base);
  text-rendering: optimizeLegibility;
}

.hourIntervalEntry {
  width: 100%;

  text-align: end;
  background-color: var(--v-component-base);

  padding-right: 5px;

  position: absolute;
  right: 10px;
  top: -8px;
}
</style>
