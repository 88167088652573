/** Given a timetable time, convert it to date range */
export function timetableTimeToDateRange (lec) {
  const startTime = new Date(0)
  const endTime = new Date(0)

  const timings = lec.time.split('-').map(t => t.trim())

  // Extract hour segments
  const startTimeHour = parseInt(timings[0].split(':')[0])
  const endTimeHour = parseInt(timings[1].split(':')[0])

  startTime.setHours(timings[0].endsWith('PM') && startTimeHour !== 12 ? startTimeHour + 12 : startTimeHour, 0, 0)
  endTime.setHours(timings[1].endsWith('PM') && endTimeHour !== 12 ? endTimeHour + 12 : endTimeHour, 0, 0)

  return {
    start: startTime,
    end: endTime
  }
}
