/** This combination of year, month and day begin on a Monday */
export const WEEK_START = {
  MON: '2021-11-01',
  TUE: '2021-11-02',
  WED: '2021-11-03',
  THU: '2021-11-04',
  FRI: '2021-11-05',
  SAT: '2021-11-06',
  SUN: '2021-11-07'
}
