import { render, staticRenderFns } from "./Chip.vue?vue&type=template&id=edd53b9e&scoped=true"
import script from "./Chip.vue?vue&type=script&lang=js"
export * from "./Chip.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edd53b9e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})
